<template>
  <div class="timeframe-wrapper" v-if="timeFrame.length">
    <div class="timeframe-section" v-for="tf in timeFrame" :key="tf.date">
      <div class="timeframe-date">{{ tf.date }}</div>
      <div class="timeframe-project my-lg" v-for="pr in tf.projects" :key="pr.date">
          <template v-if="type == 'gallery'">
            <img class="project-img" :class="pr.media_orientation" :src="pr.media_url" />
            <div class="row justify-between">
              <div>{{ pr.title }}</div>
              <span class="mx-md cursor-pointer error" @click="inquire">Inquire</span>
              <div>{{ pr.description }}</div>
            </div>
          </template>
          <template v-else>
            <div>portfolio</div>
          </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TimeFrame",
  emits: ['inquire-clicked'],
  props: {
    type: {
      type: String,
      required: false
    },
    date: {
      type: String,
      required: false,
    },
    timeFrame: {
      type: Array,
      required: false
    }
  },
  setup(props, { emit }) {
    const inquire = () => {
      emit('inquire-clicked');
    };

    return {
      inquire
    }
  },
}
</script>
<style lang="scss">
.timeframe-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.timeframe-date {
  border-radius: 40px;
  position: sticky;
  top: 10px;
  width: 40px;
  padding: 25px 20px;
  background-color: $primary;
}
.timeframe-project {
  align-self: center;
}
.project-img {
  &.horizontal {
    height: 90vh;
    width: auto;
    max-height: 590px;
  }
  &.vertical {
    height: 95vh;
  }
}
</style>